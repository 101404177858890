import {createApp, Fragment, h} from 'vue';
import { VueCookieNext } from 'vue-cookie-next'
import App from './App.vue';
import router from './router';
import translation from './translation';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import ConnectionChecker from "@/helpers/ConnectionChecker";
import TimeoutDialog from "@/components/TimeoutDialog.vue";
import CookieConsent from "@/components/CookieConsent.vue";

// enable this for testing accessibility
// const VueAxe = require('vue-axe');
// const app = createApp({
//     render: () => h(Fragment, [h(App), h(VueAxe.VueAxePopup)])
// });
// app.use(VueAxe.default);

const app = createApp(App);
app.use(router);
app.use(translation);
app.use(VueCookieNext);

document.documentElement.lang = 'en';

VueCookieNext.config({ expire: '1d', secure: 'false' });

app.mixin({
    mounted() {
        const body: HTMLElement | null = document.getElementById('body');
        if (body !== null) {
            const currentClass: string | null = body.getAttribute('data-current-class');
            if (currentClass !== null) {
                body.classList.remove(currentClass);
            }

            const newClass: string = router.currentRoute.value.name as string || '';
            if (newClass) {
                body.classList.add(newClass);
                body.setAttribute('data-current-class', newClass);
            }
        }
    }
});

app.mixin({
    mounted() {
        const sessionId: string = VueCookieNext.getCookie('vend.link');
        if (!sessionId) {
            VueCookieNext.setCookie('vend.link', uuidv4());
        }

        axios.defaults.headers.common['Session-Id'] = sessionId;
    }
});

let activeRunOnce = false;
app.mixin({
    mounted() {
        document.addEventListener('click', function () {
            ConnectionChecker.setActive(router);
        });
        if (!activeRunOnce) {
            ConnectionChecker.setActive(router);
            activeRunOnce = true;
        }
    }
});

app.component('TimeoutDialog', TimeoutDialog);
app.component('CookieConsent', CookieConsent);

app.mount('#app');
