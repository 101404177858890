import { createI18n } from "vue-i18n";
import enJson from "./locales/en.json";
import esJson from "./locales/es.json";

const messages: any = {};
messages["en"] = enJson;
messages["es"] = esJson;

export default createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    fallbackWarn: false,
    missingWarn: false,
    messages,
});
