<template>
    <div class="timeout-dialog" v-if="!checker.isActive">
        <div class="view error warning">
            <div class="internal nav-with-text">

                <div class="error-text">
                    <nav class="top">
                        <Language/>
                    </nav>

                    <h1 v-html="$t('error.timeout.header')"></h1>

                    <p class="desc" v-html="$t('error.timeout.description')"></p>

                    <div class="error-icon">
                      <div class="icon cancel"><em></em></div>
                    </div>

                    <div class="button">
                        <button class="close hidden-button" v-html="$t('error.timeout.timer', {count: count.count})"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/views/_error";
</style>

<script lang="ts">
    import {defineComponent, reactive, watch} from "vue";
    import ConnectionChecker from "@/helpers/ConnectionChecker";
    import Language from "@/components/Language.vue";
    import {Router, useRouter} from "vue-router";

    interface Counter {
        count: number;
    }

    export default defineComponent({
        components: {
            Language,
        },
        setup: () => {
            const router: Router = useRouter();

            const maxCount = 15;
            const count = reactive({
                count: maxCount
            } as Counter);
            let interval: any = null;

            const runTimeout = async () => {
                count.count --;
                if (count.count > 0) {
                    interval = setTimeout(runTimeout, 1000);
                }
                else {
                    ConnectionChecker.close(router);
                }
            };

            watch(() => ConnectionChecker.state, (state) => {
                if (!state.isActive) {
                    count.count = maxCount;
                    interval = setTimeout(runTimeout, 1000);
                }
                else if (interval) {
                    clearTimeout(interval);
                }
            }, {deep: true});

            return {
                checker: ConnectionChecker.state,
                count,
            };
        }
    });
</script>
