import { defineComponent, reactive, readonly } from "vue";
import axios, { AxiosResponse } from 'axios';
import env from "@/env";

interface ProductsResponse extends AxiosResponse {
    data: ParsedProductsResponse;
}

interface ParsedProductsResponse {
    products: Product[];
    filters: Filter[];
    quickLinks: string[];
}

export interface Product {
    id: number;
    brand: string;
    name: string;
    url: string;
    price: number;
    sale_price: number;
    max: number;
    is_new: boolean;
    quick_links: string[];
    filters: string[];
}

export interface Filter {
    code: string;
    url: string;
}

interface ProductsState {
    hasLoaded: boolean;
    products: Product[];
    activeProducts: Product[];
    filters: Filter[];
    activeFilters: string[];
    quickLinks: string[];
    activeQuickLink: string;
}

const state = reactive({
    hasLoaded: false,
    products: [],
    activeProducts: [],
    filters: [],
    activeFilters: [],
    quickLinks: [],
    activeQuickLink: '',
} as ProductsState);

const updateActiveProducts = (): void => {
    const products: Product[] = state.products;
    const filteredProducts: Product[] = [];
    for (let i = 0; i < products.length; i ++) {
        if (state.activeQuickLink !== '' && !products[i].quick_links.includes(state.activeQuickLink)) {
            continue;
        }

        if (state.activeFilters.length > 0) {
            let missingFilters = false;
            for (let j = 0; j < state.activeFilters.length; j ++) {
                if (!products[i].filters.includes(state.activeFilters[j])) {
                    missingFilters = true;
                }
            }

            if (missingFilters) {
                continue;
            }
        }

        filteredProducts.push(products[i]);
    }

    state.activeProducts = filteredProducts;
};

const load = async (podSearchId: string): Promise<void> => {
    try {
        let products: AxiosResponse;

        if (podSearchId === 'demo') {
            products = await axios.get('/demo/products.json') as ProductsResponse;
        }
        else {
            products = await axios.get(env.API_URL + '/' + podSearchId + '/products') as ProductsResponse;
        }

        state.hasLoaded = true;
        state.filters = products.data.filters;
        state.products = products.data.products;
        state.quickLinks = products.data.quickLinks;

        updateActiveProducts();
    }
    catch (error) {
        console.log(error.response.data);

        throw error;
    }
};

const setActiveFilters = (activeFilters: string[]): void => {
    state.activeFilters = activeFilters;

    updateActiveProducts();
};

const setActiveQuickLink = (link: string): void => {
    state.activeQuickLink = link;

    updateActiveProducts();
};

const getProduct = (productId: number): Product | null => {
    for (let i = 0; i < state.products.length; i ++) {
        if (state.products[i].id === productId) {
            return state.products[i];
        }
    }

    return null;
};

export default defineComponent({
    state: readonly(state),
    setActiveFilters,
    setActiveQuickLink,
    load,
    getProduct,
});
