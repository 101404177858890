
    import { defineComponent, ref } from 'vue';
    import { useI18n } from "vue-i18n";

    export default defineComponent({
        setup: () => {
            const betterLocale = ref(useI18n().locale.value);

            const handleChange = (a: FocusEvent) => {
                const target: HTMLSelectElement = a.target as HTMLSelectElement;
                betterLocale.value = target.options[target.selectedIndex].value;
            };

            return {
                handleChange,
                betterLocale
            };
        }
    });
