
    import {defineComponent, reactive, watch} from "vue";
    import ConnectionChecker from "@/helpers/ConnectionChecker";
    import Language from "@/components/Language.vue";
    import {Router, useRouter} from "vue-router";

    interface Counter {
        count: number;
    }

    export default defineComponent({
        components: {
            Language,
        },
        setup: () => {
            const router: Router = useRouter();

            const maxCount = 15;
            const count = reactive({
                count: maxCount
            } as Counter);
            let interval: any = null;

            const runTimeout = async () => {
                count.count --;
                if (count.count > 0) {
                    interval = setTimeout(runTimeout, 1000);
                }
                else {
                    ConnectionChecker.close(router);
                }
            };

            watch(() => ConnectionChecker.state, (state) => {
                if (!state.isActive) {
                    count.count = maxCount;
                    interval = setTimeout(runTimeout, 1000);
                }
                else if (interval) {
                    clearTimeout(interval);
                }
            }, {deep: true});

            return {
                checker: ConnectionChecker.state,
                count,
            };
        }
    });
