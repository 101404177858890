
    import {defineComponent} from "vue";
    import {RouteLocationNormalizedLoaded, Router, useRoute, useRouter} from "vue-router";
    declare const gtag: any;

    export default defineComponent({
        setup: () => {
            const route: RouteLocationNormalizedLoaded = useRoute();
            const router: Router = useRouter();

            const acceptCookies = () => {
                const date = new Date();
                date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));

                document.cookie = "can_cookie=1; expires=" + date.toUTCString() + "; path=/";
                const app = document.getElementById("app");
                if (app) {
                    app.classList.remove("has-cookie-consent");
                }

                gtag('consent', 'update', {
                    'analytics_storage': 'granted'
                });
            };

            const cookies = () => {
                router.push({
                    name: 'cookie',
                    params: {
                        id: route.params.podSearchId,
                    }
                });
            };

            return {
                acceptCookies,
                cookies
            };
        }
    });
