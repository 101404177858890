import {defineComponent, reactive, readonly} from "vue";
import axios, { AxiosResponse } from 'axios';
import { Router } from "vue-router";
import env from "@/env";

interface PingResponse extends AxiosResponse {
    data: ParsedPingResponse;
}

interface ParsedPingResponse {
    timestamp: number;
}

interface ConnectionCheckerState {
    isActive: boolean;
}

const state = reactive({
    isActive: true,
} as ConnectionCheckerState);

let _podId: string;
let _pingInterval: any;
const _pingIntervalLength: number = 2 * 1000;
let _activeTimeout: any;
const _activeTimeoutLength: number = 60 * 1000;

const setPodId = (podId: string): void => {
    _podId = podId;
};

const isDemo = (): boolean => {
    return _podId === "demo";
};

const lost = (router: Router, podSearchId: string): void => {
    router.push({
        name: 'connection_error',
        params: {
            podSearchId: podSearchId,
            reason: 'lost',
        },
    });

    if (_pingInterval) {
        clearInterval(_pingInterval);
    }
};

const failed = (router: Router, podSearchId: string): void => {
    router.push({
        name: 'connection_error',
        params: {
            podSearchId: podSearchId,
            reason: 'failed',
        },
    });

    if (_pingInterval) {
        clearInterval(_pingInterval);
    }
};

const check = async (): Promise<PingResponse> => {
    try {
        let response = null;
        if (isDemo()) {
            response = await axios.get('/demo/ping.json');
        }
        else {
            response = await axios.get(env.API_URL + '/' + _podId + '/ping');
        }

        if (response.data.timestamp > (Date.now() / 1000)) {
            throw new Error("timeout due to timestamp");
        }

        return response;
    }
    catch (error) {
        console.log(error);

        throw error;
    }
};

const start = (router: Router, podSearchId: string): void => {
    _pingInterval = setInterval(() => {
        check().catch(() => {
            lost(router, podSearchId);
        });
    }, _pingIntervalLength);
};

const close = async (router: Router): Promise<void> => {
    try {
        if (_pingInterval) {
            clearInterval(_pingInterval);
        }

        if (!isDemo()) {
            try {
                await axios.get(env.API_URL + '/' + _podId + '/close');
            }
            catch (error) {
                console.log("failed to close");
            }
        }

        await router.push({
            name: 'connection_error',
            params: {
                podSearchId: _podId,
                reason: 'closed',
            },
        });

        state.isActive = true;
    }
    catch (error) {
        lost(router, _podId);
    }
};

const setActive = (router: Router): void => {
    state.isActive = true;
    if (_activeTimeout) {
        clearTimeout(_activeTimeout);
    }
    _activeTimeout = setTimeout(() => {
        let routeName = '';
        if (router.currentRoute && router.currentRoute.value && router.currentRoute.value.name) {
            routeName = router.currentRoute.value.name as string;
        }
        if (!['connection_error', 'homepage', 'loading'].includes(routeName)) {
            state.isActive = false;
        }
    }, _activeTimeoutLength);
};

const stopPingCheck = () => {
    if (_pingInterval) {
        clearInterval(_pingInterval);
    }
};

export default defineComponent({
    state: readonly(state),
    start,
    failed,
    close,
    setPodId,
    isDemo,
    stopPingCheck,
    setActive
});
