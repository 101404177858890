<template>
    <TimeoutDialog/>
    <router-view v-slot="{ Component }">
        <component :is="Component" class="child-view" />
    </router-view>
    <CookieConsent/>
</template>

<style lang="scss">
    @import "./styles/app.scss";
</style>
