import {
  createRouter,
  createWebHistory, NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw, RouterOptions
} from 'vue-router';
import env from "@/env";
import Products from "@/stores/Products";

function redirectToLoading(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
  if (!Products.state.hasLoaded && from.name !== 'loading') {
    next({
      name: 'loading',
      params: {
        podSearchId: to.params.podSearchId,
      },
    });
  } else {
    next();
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'homepage',
    component: () => import(/* webpackChunkName: "home" */ '../views/Homepage.vue')
  } as RouteRecordRaw,


  {
    path: '/:podSearchId',
    name: 'loading',
    component: () => import(/* webpackChunkName: "main" */ '../views/Loading.vue'),
  } as RouteRecordRaw,
  {
    path: '/:podSearchId/list',
    name: 'list',
    component: () => import(/* webpackChunkName: "main" */ '../views/List.vue'),
    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      redirectToLoading(to, from, next);
    }
  } as RouteRecordRaw,
  {
    path: '/:podSearchId/filter',
    name: 'filters',
    component: () => import(/* webpackChunkName: "main" */ '../views/Filter.vue'),
    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      redirectToLoading(to, from, next);
    }
  } as RouteRecordRaw,
  {
    path: '/:podSearchId/info/:productId',
    name: 'info',
    component: () => import(/* webpackChunkName: "main" */ '../views/Info.vue'),
    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      redirectToLoading(to, from, next);
    }
  } as RouteRecordRaw,


  {
    path: '/:podSearchId/checkout',
    name: 'checkout',
    component: () => import(/* webpackChunkName: "main" */ '../views/Checkout.vue'),
    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      redirectToLoading(to, from, next);
    }
  } as RouteRecordRaw,
  {
    path: '/:podSearchId/payment/loading',
    name: 'payment_loader',
    component: () => import(/* webpackChunkName: "main" */ '../views/PaymentLoader.vue'),
    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      redirectToLoading(to, from, next);
    }
  } as RouteRecordRaw,
  {
    path: '/:podSearchId/payment',
    name: 'payment',
    component: () => import(/* webpackChunkName: "main" */ '../views/Payment.vue'),
    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      redirectToLoading(to, from, next);
    }
  } as RouteRecordRaw,


  {
    path: '/:podSearchId/quit',
    name: 'cancel',
    component: () => import(/* webpackChunkName: "main" */'../views/errors/Cancel.vue'),
    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      redirectToLoading(to, from, next);
    }
  } as RouteRecordRaw,
  {
    path: '/:podSearchId/connection-error',
    name: 'connection_error',
    props: true,
    component: () => import(/* webpackChunkName: "main" */'../views/errors/ConnectionError.vue'),
    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      redirectToLoading(to, from, next);
    }
  } as RouteRecordRaw,

  {
    path: '/:podSearchId/basket-total-limit',
    name: 'basket_total_limit',
    component: () => import(/* webpackChunkName: "main" */'../views/errors/BasketTotalLimit.vue'),
    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      redirectToLoading(to, from, next);
    }
  } as RouteRecordRaw,
  {
    path: '/:podSearchId/basket-item-limit',
    name: 'basket_item_limit',
    component: () => import(/* webpackChunkName: "main" */'../views/errors/BasketItemLimit.vue'),
    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      redirectToLoading(to, from, next);
    }
  } as RouteRecordRaw,

  {
    path: '/:podSearchId/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "main" */ '../views/Help.vue'),
    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      redirectToLoading(to, from, next);
    }
  } as RouteRecordRaw,
  {
    path: '/:podSearchId/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "main" */ '../views/Terms.vue'),
    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      redirectToLoading(to, from, next);
    }
  } as RouteRecordRaw,
  {
    path: '/:podSearchId/cookie',
    name: 'cookie',
    component: () => import(/* webpackChunkName: "main" */ '../views/Cookie.vue'),
    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      redirectToLoading(to, from, next);
    }
  } as RouteRecordRaw,
];

export default createRouter({
  history: createWebHistory(env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      window.scrollTo(savedPosition.left, savedPosition.top);

      return;
    }

    const app: HTMLElement | null = document.getElementById('app');
    if (app) {
      app.scrollIntoView();
    }
  }
} as RouterOptions);
